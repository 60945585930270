.koala-card-title {
  font-size: 18px;
  color: #D3D5E5;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  line-height: 1.3;
  letter-spacing: 0.0075rem;
}

.koala-card-subtitle {
  font-size: 20px;
  color: $koala-section-title;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  line-height: 1.6;
  letter-spacing: 0.0075rem;
}

.koala-card-message {
  font-size: 14px;
  color: $koala-section-title;
}
