.c-blue {
  color: $koala-blue !important;
}
.c-white {
  color: $koala-white;
}

.bg-white {
  background-color: $koala-white !important;
}

.bg-card {
  background-color: $card-bg;
}

.bg-blue {
  background-color: $koala-blue !important;
}

.bg-yellow {
  background-color: lightgoldenrodyellow !important;
}
