@import 'partials/_variables';

@import "~react-toastify/scss/main";
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/mixins';
@import '~bootstrap/scss/variables';

@import "~bootstrap/scss/utilities/sizing";
@import "~bootstrap/scss/utilities/visibility";
@import "~bootstrap/scss/utilities/text";
@import "~bootstrap/scss/utilities/borders";
@import "~bootstrap/scss/utilities/background";
@import "~bootstrap/scss/bootstrap-grid";
@import "~bootstrap/scss/alert";
@import "~bootstrap/scss/close";
@import "~bootstrap/scss/dropdown";
// po pelnej migracji na Material Design te style nie beda potrzebne
@import "~bootstrap/scss/card";
@import "~bootstrap/scss/forms";
@import "~bootstrap/scss/input-group";
@import "~bootstrap/scss/popover";
@import "~bootstrap/scss/badge";

@import 'partials/_normalize';
@import 'partials/_z-index';
@import 'partials/_menu';
@import 'partials/_user-navigation';
@import 'partials/_circle';
@import 'partials/_koala-box';
@import 'partials/_koala-card';
@import 'partials/_divider';
@import 'partials/_form';
@import 'partials/_colors';
@import 'partials/_fonts';
@import 'partials/_map';
@import 'partials/_arrow';
@import 'partials/_cursors';
@import 'partials/_react-date-range';
@import 'partials/_material-design';
@import 'partials/_utils';
@import 'partials/_width';
@import 'partials/_leaflet-extra-markers';
@import 'partials/_leaflet';
@import 'partials/_btn';
@import 'partials/_react-virtualized';
@import 'partials/_tooltip';
@import 'partials/_fix';
@import 'partials/_preloader';
//@import 'partials/_leaflet.awesome-markers';


//@import '~react-virtualized/styles.css';
@import '~tabulator-tables/dist/css/bulma/tabulator_bulma.min.css';


.ReactVirtualized__Table__headerRow,
.ReactVirtualized__Table__row:nth-child(even),
.ReactVirtualized__Table__row:nth-child(odd) {
  border-bottom: 1px solid #e0e0e0;
}
.ReactVirtualized__Table__row:nth-child(odd) {
  background-color: #fafafa;
}

html body {
  color: $body-color;
  --text-color: $body-color;
}

body .main-sidebar {
  left: 0;
  position: relative;
  height: auto;
}

body .content-wrapper, body .content {
  background-color: $koala-background-color;
}

body.easyadmin .content {
  background-color: #ffffff;
}

body.easyadmin .content-body .form-horizontal, body.easyadmin .content-body form {
  background: var(--form-bg) !important;
}

.modal-popover, div[role="tooltip"] {
  @include z-index(popper);
}

.koala-form {
  .daterangepicker {
    position: static;
    border: 0;
  }
}

.full-height {
  min-height: 100vh;
}

.topfive .nav-tabs {
  border-bottom: none !important;
}
.topfive #tabs .nav-tabs .nav-item.show .nav-link,.topfive .nav-tabs .nav-link.active {
  color: rgba(0, 0, 0, 0.98);
  background-color: transparent;
  border-color: transparent transparent #00a6ec;
  //border-bottom: 1px solid !important;
  //font-size: 16px;
  //font-weight: bold;
}
.topfive .nav-link {
  border: 1px solid transparent;
  //border-top-left-radius: .25rem;
  //border-top-right-radius: .25rem;
  color: #626262;
  //font-size: 16px;
  //font-weight: 600;
}
.topfive .nav-link:hover {
  //border: none;
}

#navbar #popper-date-range-filter {
  color: #3c3c3c;
}


tr.shoppingMallPerformance {
  &:hover {
    background-color: #f2f2f2;
    cursor: pointer;
  }
}

@font-face {
  font-family: "DraftA-Medium";

  src: url("/assets/fonts/DraftA-Medium.woff") format("woff"), /* Modern Browsers */
  url("/assets/fonts/DraftA-Medium.woff2") format("woff2"); /* Modern Browsers */
  font-weight: normal;
  font-style: normal;
}

body.page-login {
  background-color: #1ea7d2;
}

.tabulator {
  font-size: .875rem;
}
.tabulator-row.tabulator-selected {
  background-color: #9abcea38;
}
.tabulator-row.tabulator-selected:hover {
  background-color: #9abcea4a;
  cursor: pointer;
}
.tabulator-row.tabulator-edited {
  background: #ffc10730!important;
}
.tabulator-row.tabulator-row-even {
  background-color: var(--form-bg);
}
.tabulator .tabulator-header .tabulator-col:first-child {
  text-align: center;
}
.tabulator .tabulator-header,
.tabulator .tabulator-footer {
  background-color: var(--form-bg) !important;
}
.tabulator .tabulator-footer {
  text-align: left;
  font-weight: normal;
}
.poi_search {
  background-color: var(--form-bg) !important;
}

.tabulator-row .tabulator-cell .tabulator-responsive-collapse-toggle {
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -o-user-select: none;
  height: 15px;
  width: 15px;
  border-radius: 20px;
  background: #666;
  color: #fff;
  font-weight: bold;
  font-size: 1.1em;
}
.sidebar-menu .header {
  margin-left: 10px;
}

table.MuiTable-root {
  .MuiTableCell-root {
    padding: 0px 7px;
  }
  td {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;

    div {
      white-space: normal;
    }
  }
  .MuiTableCell-head, .MuiTableSortLabel-active {
    color: rgba(63, 81, 181, 0.9);

    & > span:hover {
      color: rgba(63, 81, 181, 0.9);
    }
  }
}
//.MuiTableRow-root td:nth-child(4),.MuiTableRow-root td:nth-child(5),.MuiTableRow-root td:nth-child(6),.MuiTableRow-root td:nth-child(7) {
.MuiTableRow-root td {
  font-size: 90% !important;
}
//.MuiTableRow-root td:nth-child(2) {
//  font-weight: bold;
//}


.bg-justtag-primary {
  background-color: #00ba9a;
}
.bg-justtag-secondary {
  background-color: rgba(63, 81, 181, 0.9);
}

.badge-justtag {
  text-transform: uppercase;
  font-size: 90%;
  padding: 0.25rem !important;
  padding-left: 0.5rem !important;
  padding-right: 0.5rem !important;
  display: inline-block !important;
  border-radius: 0.25rem !important;
  font-size: 90%;
  color: #f8f9fa !important;
}
.block-drop-tree {
  padding: 0.375rem 0.75rem;

  div.ant-select.ant-tree-select {
    div.ant-select-selector {
      color: #495057;
      border: 1px solid #ced4da;
      border-radius: 0.25rem;
      min-height: calc(1.5em + 0.75rem + 2px);
    }
  }
}
div.custom-drop-tree {
  min-height: 50px;

  &.ant-select-dropdown.ant-tree-select-dropdown {
    background: #e5e5e5;
    z-index: 1500;

    div.ant-select-tree-treenode {
      padding-bottom: 0;
      background: #e5e5e5;
      height: 50px;
      font-size: 0.9rem;
      display: flex;
      box-sizing: border-box;
      text-align: left;
      align-items: center;

      &:hover {
        background: rgba(0, 0, 0, 0.15);
      }

      span.ant-select-tree-node-content-wrapper {
        flex: 1 1 auto;
        min-width: 0;
        margin-top: 4px;
        margin-bottom: 4px;

        &:hover {
          background-color: rgba(0, 0, 0, 0);
        }
      }

      span.ant-select-tree-switcher {
        color: $koala-middle-grey;
        display: inline-flex;
        min-width: 15px;
        flex-shrink: 0;

        span.ant-select-tree-switcher-icon {
          line-height: 25px;
        }
      }

      span.ant-select-tree-checkbox {
        margin-top: 0;
        color: $koala-middle-grey;
        display: inline-flex;
        min-width: 20px;
        flex-shrink: 0;
      }
    }
  }
}