$z-index: (
        popper : 1200, // ze wzgledu na leaflet
        navbar : 1300,
);

@function z-index($key) {
  @return map-get($z-index, $key);
}

@mixin z-index($key) {
  z-index: z-index($key);
}
