    //$koala-blue: #3b86ff !default;
$koala-blue:                    #3f51b5 !default;
$koala-blue-half-light:         #BFC7EE !default;
$koala-dark-blue:               #00004b !default;
$koala-white:                   #ffffff !default;
$koala-green:                   #50d8bb !default;
$koala-red:                     #fe826e !default;
$koala-background-color:        #f2f2f2 !default;
$koala-section-title:           #818AA9 !default;
$koala-grey:                    #f2f2f2 !default;
$koala-middle-grey:             #757575 !default;
$koala-scroll-track:            $koala-grey !default;
$koala-scroll-thumb:            #bdc1c6 !default;

$menu-background:               #525a65 !default;
$menu-background-active:        #424b58 !default;
$menu-color-active:             $koala-blue !default;
$menu-font-color:               #ffffff !default;

$body-color:                    #3c3c3c !default;
$font-size-base:                0.9rem !default;

// Spacing
//
// Control the default styling of most Bootstrap elements by modifying these
// variables. Mostly focused on spacing.
// You can add more entries to the $spacers map, should you need more variation.
$spacer: 1rem !default;
$spacers: () !default;
$spacers: map-merge(
    (
            0: 0,
            1: ($spacer * .25),
            2: ($spacer * .5),
            3: $spacer,
            4: ($spacer * 1.5),
            5: ($spacer * 2)
    ),
    $spacers
);
