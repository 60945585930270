//poi marker red green

$colors: (
    'red': $koala-red,
    'green': $koala-green,
);

@each $name, $color in $colors {
  .marker-icon-#{$name} {
    border: 5px solid $color;
    border-radius: 15px;
    width: 15px !important;
    height: 15px !important;
    display: inline-block;
  }
}
