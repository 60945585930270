@import '~leaflet/dist/leaflet.css';
@import '~leaflet.markercluster/dist/MarkerCluster.css';
@import '~leaflet.markercluster/dist/MarkerCluster.Default.css';

.leaflet-container {
  height: 100%;
  width: 100%;
}

/* Default icon URLs */
.leaflet-default-icon-path {
  background-image: url('/assets/images/marker-icon.png');
}
.leaflet-default-shadow-path {
  background-image: url('/assets/images/marker-shadow.png');
}

.leaflet-marker-icon {
  background-image: url('/assets/images/marker-icon.png');
  &.marker-cluster {
    background-image: none;
  }
}
