// stylelint-disable selector-no-qualifying-type

//
// Base styles
//

.btn {
  display: inline-block;
  font-family: $btn-font-family;
  font-weight: $btn-font-weight;
  color: $body-color;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
  border: $btn-border-width solid transparent;
  @include button-size($btn-padding-y, $btn-padding-x, $btn-font-size, $btn-line-height, 0);
  @include transition($btn-transition);

  @include hover {
    text-decoration: none;
  }

  &:focus,
  &.focus {
    outline: 0;
    box-shadow: none;
  }

  // Disabled comes first so active can properly restyle
  &.disabled,
  &:disabled {
    opacity: $btn-disabled-opacity;
    @include box-shadow(none);
  }
}

// Future-proof disabling of clicks on `<a>` elements
a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none;
}


//
// Alternate buttons
//

$button-colors: ("black": #000000, "gray": #E0E1E2, "darkgray": #C0C1C2);

@each $color, $value in $button-colors {
  .btn-#{$color} {
    color: color-yiq($value);
    @include gradient-bg($value);
    border-color: $value;
  }
}

@each $color, $value in $button-colors {
  .btn-outline-#{$color} {
    color: $value;
    border-color: $value;
  }
}
