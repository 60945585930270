// Base class
.bs4-tooltip {
  position: absolute;
  z-index: $zindex-tooltip;
  display: block;
  margin: $tooltip-margin;
  // Our parent element can be arbitrary since tooltips are by default inserted as a sibling of their target element.
  // So reset our font and text properties to avoid inheriting weird values.
  @include reset-text();
  @include font-size($tooltip-font-size);
  // Allow breaking very long words so they don't overflow the tooltip's bounds
  word-wrap: break-word;
}


// Wrapper for the tooltip content
.bs4-tooltip-inner {
  max-width: $tooltip-max-width;
  padding: $tooltip-padding-y $tooltip-padding-x;
  color: $tooltip-color;
  text-align: center;
  background-color: $tooltip-bg;
  @include border-radius($tooltip-border-radius);
}
