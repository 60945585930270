.fs-48 {
  font-size: 48px !important;
}

.fs-h2 {
  font-size: 1.75rem !important;
}

.fs-bigger {
  font-size: 1.2em !important;
}

.section-title {
  color: $koala-section-title;
  font-size: 1rem;
}
