body {
  .form-wrapper .select2.select2-container.select2-container--bootstrap4 {
    background-color: transparent;
  }
  .content-body form {
    background-color: $menu-background-active;
  }

  .select2-container--bootstrap4.select2-container {
    background-color: transparent;
    border: 0;
    border-bottom: 3px solid white;

    /* needed */
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    /* SVG background image */
    background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='%23ffffff'><polygon points='0,0 100,0 50,50'/></svg>") no-repeat;
    background-size: 12px;
    background-position: calc(100% - 10px) center;
    background-color: transparent;

    .select2-selection__rendered {
      width: calc(100% - 25px);
    }

    .select2-search__field {
      background-color: transparent;
    }

    .select2-selection {
      border: 0;
    }

    &--focus .select2-selection {
      border-color: transparent;
      -webkit-box-shadow: none;
      box-shadow: none;
    }

    .select2-selection--multiple .select2-selection__choice {
      background-color: white;
    }

    @mixin placeholder {
      color: white;
      font-weight: bold;
    }

    input::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
      @include placeholder;
      opacity: 1; /* Firefox */
    }

    input::-ms-input-placeholder { /* Internet Explorer 10-11 */
      @include placeholder;
    }

    input::-ms-input-placeholder { /* Microsoft Edge */
      @include placeholder;
    }
  }
}
