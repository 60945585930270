$transition-duration: 2s;
$path-length: 157px; // Retrieved using SVG's getTotalLength()

#preloader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10000;
  background: rgba(0,0,0,.5);
  padding: 0;
  margin: 0;

  #preloader-content {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;


    svg {
      overflow: visible;
      width: 100px;
      height: 150px;

      g {
        animation: slide $transition-duration linear infinite;

        &:nth-child(2) {
          animation-delay: $transition-duration / 4;

          path {
            animation-delay: $transition-duration / 4;
            stroke-dasharray: 0 $path-length + 1;
            stroke-dashoffset: 1px;
          }
        }
      }

      path {
        stroke: url(#gradient);
        stroke-width: 20px;
        stroke-linecap: round;
        fill: none;
        stroke-dasharray: 0 $path-length;
        stroke-dashoffset: 0;
        animation: escalade $transition-duration cubic-bezier(0.8, 0, 0.2, 1) infinite;
      }
    }

    @keyframes slide {
      0% {
        transform: translateY(-50px);
      }
      100% {
        transform: translateY(50px);
      }
    }

    @keyframes escalade {
      0% {
        stroke-dasharray: 0 $path-length;
        stroke-dashoffset: 0;
      }
      50% {
        stroke-dasharray: $path-length - 1 $path-length;
        stroke-dashoffset: 0;
      }
      100% {
        stroke-dasharray: $path-length - 1 $path-length;
        stroke-dashoffset: -($path-length - 1);
      }
    }

  }
}