//menu
.toggle-menu {
  font-size: 1.75em;
  padding-right: 0.5em;
  background-color: $menu-background;
  color: $menu-font-color;
}

body .main-sidebar {
  background-color: $menu-background;
  color: $menu-font-color;

  .sidebar-menu a {
    color: $menu-font-color;
  }
  .sidebar-menu.tree {
    .fa {
      color: $menu-font-color;
    }
    li {
      padding: 0.5em 1em;

      &:hover {
        background-color: $menu-background-active;
      }
    }
    li.active {
      background-color: $menu-background-active;
      border-left: 6px solid $menu-color-active;
      a {
        color: $menu-font-color;
        &:visited, &:active {
          color: $menu-font-color;
        }
      }
      .fa {
        color: $menu-color-active;
      }
    }
  }
}

body.active-sidebar {
  overflow: hidden;
}

#navbar {
  //position: fixed;
  @include z-index(navbar);
  background-color: $koala-blue;
  color: #ffffff;
}

#sidenav {
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 1800;
  top: 0;
  left: 0;
  background-color: $menu-background;
  color: $menu-font-color;
  overflow-x: hidden;
  transition: 0.5s;
  padding-top: 60px;

  ul {
    padding-left: 0;
  }

  li {
    list-style: none;
  }

  .closebtn {
    position: absolute;
    top: 0;
    right: 25px;
    font-size: 36px;
    margin-left: 50px;
  }

  .sidebar-menu a {
    color: $menu-font-color;
    padding: 0.5em 1em;
    display: block;
  }
  .sidebar-menu {
    .fa {
      color: $menu-font-color;
    }
    li {
      &:hover {
        background-color: $menu-background-active;
      }
      a:hover {
        text-decoration: none;
      }
    }
    li.active {
      background-color: $menu-background-active;
      border-left: 6px solid $menu-color-active;
      a {
        color: $menu-font-color;
        &:visited, &:active {
          color: $menu-font-color;
        }
      }
      .fa {
        color: $menu-color-active;
      }
    }
  }
}


#main {
  transition: margin-left .5s;
}
