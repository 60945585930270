.w-30 {
  width: 30% !important
}

.w-22 {
  width: 22% !important
}

.w-20 {
  width: 20% !important
}
