.koala-box-shadow {
  box-shadow: 2px 8px 14px 2px rgba(0,0,0,0.1);
}

.koala-material-box-shadow {
  box-shadow: 2px 4px 10px 0 rgba(0,0,0,0.1);
}

.h-100 {
  height: 100%;
}

.list-unstyled {
  @include list-unstyled;
}

.user-segment-widget-filters {
  max-width: 380px;
  button {
    margin-bottom: 3px;
  }
}