@mixin circle($size) {
  display: inline-block;
  border-radius: 50%;
  width: $size;
  height: $size;
}

.circle-red {
  @include circle(8px);
  background-color: red;
}

.circle-blue {
  @include circle(8px);
  background-color: $koala-blue;
}

.circle-orange {
  @include circle(8px);
  background-color: #fbb086;
}

.circle-yellow {
  @include circle(8px);
  background-color: #ffcc00;
}

.circle-green {
  @include circle(8px);
  background-color: #3ccf49;
}
