.MuiTableRow-head .MuiTableCell-head .MuiCheckbox-root {
  color: white;
}

.MuiTableHead-root .MuiTableCell-head {
  font-size: 0.875rem;
}

.MuiTabs-scroller .MuiTabs-indicator {
  background-color: $koala-dark-blue;
}

body {
  .MuiPaper-elevation1, .MuiPaper-elevation2 {
    box-shadow: 2px 4px 10px 0 rgba(0,0,0,0.1);
  }
}

#popper-locations-filter,
#add-new-group,
#add-stores-to-group {
  .MuiTableBody-root .MuiTableRow-root .MuiTableCell-body .MuiCheckbox-colorSecondary.Mui-checked {
    color: $koala-dark-blue;
  }
  .MuiTypography-h6 {
    font-size: 0.8rem;
  }
  .MuiToolbar-regular {
    min-height: 48px;
  }
  .MuiCheckbox-colorSecondary.Mui-checked {
    color: $koala-dark-blue;
  }
}

.MuiExpansionPanel-root {
  border-left: 2px solid $koala-blue;
  border-top: 1px solid transparent;
  border-bottom: 1px solid transparent;;
  border-right: 1px solid transparent;;
  padding: 12px 0;
  &:hover, &.Mui-expanded {
    border-top: 1px solid $koala-blue;
    border-bottom: 1px solid $koala-blue;
    border-right: 1px solid $koala-blue;
  }
  .MuiExpansionPanelSummary-root {
    .MuiExpansionPanelSummary-expandIcon {
      color: $koala-blue;
    }
  }
  .MuiTypography-root {
    color: $koala-section-title;
  }
  &.Mui-expanded {
    .expansion-panel-title {
      color: $koala-blue;
    }
  }
}
